<template>
  <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
  >
    <template #button-content>
      <b-avatar v-if="currentLocal.locale === 'en'"
                size="30"
                src="@/assets/flags/gb.svg"
                variant="light-primary"
                class="badge-minimal"
      >
      </b-avatar>
      <b-avatar v-else
                size="30"
                src="@/assets/flags/se.svg"
                variant="light-primary"
                class="badge-minimal"
      >
      </b-avatar>
      <span class="ml-50 text-body">{{ currentLocal.name }}</span>
      <!-- <feather-icon size="16" icon="ChevronDownIcon" class="mr-1" /> -->
    </template>

    <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="changeLocale(localeObj.locale)"
    >
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
import {mapGetters} from "vuex"

export default {
  data() {
    return {
      locales: [
        {
          locale: 'en',
          name: 'English',
        },
        {
          locale: 'sv',
          name: 'Svenska',
        },
      ],
      currentLocal: {}
    };
  },
  created() {
    this.setCurrent()
  },
  methods: {
    changeLocale(value) {
      this.$i18n.locale = value;
      this.$store.commit('appConfig/CHANGE_LANGUAGE', value)
      this.setCurrent()
    },
    setCurrent() {
      let locale = window.localStorage.getItem('lang')
      if (locale === null) {
        this.$i18n.locale = 'sv';
        this.$store.commit('appConfig/CHANGE_LANGUAGE', 'sv')
      }
      const lang = this.locales.find(l => l.locale === window.localStorage.getItem('lang'));
      if (lang) {
        this.currentLocal = lang
      }
      this.currentLocal = lang

    }
  },
  computed: {
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },

  mounted() {
    let locale = window.localStorage.getItem('lang') !== null ? window.localStorage.getItem('lang') : 'sv'
    this.$i18n.locale = locale;
    this.$store.commit('appConfig/CHANGE_LANGUAGE', locale)

  },

};
</script>

<style></style>
