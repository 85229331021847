<template>
    <div class="bg-white mb-1 overflow-hidden border-b"><!--v-wave -->
        <div class="flex justify-between items-center text-xs cursor-pointer p-2" @click="openAccordion">
            <span class="text-2xl text-gray-800" v-if="GET_LOCALE === 'sv'">{{ data.swe_question }}</span>
            <span class="text-2xl text-gray-800" v-else>{{ data.eng_question }}</span>
            <div>
                <span v-if="active">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-700">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </span>
                <span v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-700">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </span>
            </div>
            
        </div>
        <transition name="slide">
            <div v-if="active" class="mb-1 px-2">
                <div class="text-gray-500 text-lg" v-html="data.swe_answer" v-if="GET_LOCALE === 'sv'"></div>
                <div class="text-gray-500 text-lg" v-html="data.eng_answer" v-else></div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
    export default {
        name : 'accordion-faq',
        props: {
            data : {
                type : Object,
                required : false
            }
        },
        data () {
            return {
                active : false,
            }
        },
        methods : {
            openAccordion () {
                this.active = !this.active
            },
        },
      computed:{
          ...mapGetters({
            GET_LOCALE: 'appConfig/getLocale'
          })
      }
    }
</script>

<style lang="scss" scoped>
// .cc-color{
//     color: #736f6f !important;
// }
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
.rounded{
    border-radius: 0.8rem !important;
}
.collapse-icon{
    // color: $theme-primary-color !important;
}
.border-theme-color{
    // border-color: $form-border !important;
}
.card-header{
    border-bottom: unset !important;
}
.b-top{
    // border-top: 1px solid $form-border !important;
}
</style>
