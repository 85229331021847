<template>
  <div>
    <header class="absolute w-full z-30">
      <div class="px-8 mx-auto">
        <div class="flex justify-between items-center h-16 mt-6">
          <div class="flex justify-between items-center">
            <div class="flex-none text-base text-white">
              <img
                style="width: 100px"
                src="./../../../assets/golfigo/logo.png"
                alt=""
              />
            </div>
            <nav class="custom-hidden flex-1 md:flex justify-end">
              <ul class="flex justify-between items-center text-gray-800">
                <li>
                  <router-link
                    :to="{ name: 'landing-page' }"
                    class="px-8 py-2 text-gray-800"
                    >{{ $t("NavbarFirst.home") }}
                  </router-link>
                </li>
                <li>
                  <a
                    href="#"
                    v-scroll-to="'#function-section'"
                    v-if="['landing-page'].includes($route.name)"
                    class="px-8 py-2 text-gray-800"
                    >{{ $t("NavbarFirst.function") }}</a
                  >
                  <a
                    href="javascript:void(0)"
                    v-on:click="onToggle('function-section')"
                    v-else
                    class="px-8 py-2 text-gray-800"
                  >
                    {{ $t("NavbarFirst.function") }}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    v-scroll-to="'#price-section'"
                    v-if="['landing-page'].includes($route.name)"
                    class="px-8 py-2 text-gray-800"
                    >{{ $t("NavbarFirst.price") }}</a
                  >
                  <a
                    href="javascript:void(0)"
                    v-on:click="onToggle('price-section')"
                    v-else
                    class="px-8 py-2 text-gray-800"
                  >
                    {{ $t("NavbarFirst.price") }}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    v-scroll-to="'#faq-section'"
                    v-if="['landing-page'].includes($route.name)"
                    class="px-8 py-2 text-gray-800"
                    >FAQ</a
                  >
                  <a
                    href="javascript:void(0)"
                    v-on:click="onToggle('faq-section')"
                    v-else
                    class="px-8 py-2 text-gray-800"
                    >FAQ</a
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'contact-page' }"
                    class="px-8 py-2 text-gray-800"
                  >
                    {{ $t("NavbarFirst.contact") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'blog-page' }"
                    class="px-8 py-2 text-gray-800"
                    >{{ $t("NavbarFirst.blog") }}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <nav class="custom-hidden flex-1 md:flex justify-end">
            <ul class="flex justify-between items-center text-gray-800">
              <li>
                <span class="px py-2 text-gray-800">
                  <locale />
                </span>
              </li>
              <li>
                <router-link
                  :to="{ name: 'login' }"
                  class="px-8 py-2 text-gray-800"
                  >{{ $t("NavbarFirst.login") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'price' }"
                  class="px-8 py-1 text-white bg-theme-sidebar rounded-md"
                >
                  {{ $t("NavbarFirst.register") }}
                </router-link>
              </li>
            </ul>
          </nav>
          <button @click="showMobileSidebar = true" class="flex md:hidden">
            <svg
              id="bar"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-theme-teal"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <svg
              id="close"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-theme-teal hidden"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        id="backdrop-mobile"
        v-if="showMobileSidebar"
        @click="showMobileSidebar = false"
        class="fixed inset-0 z-30 backdrop"
      ></div>
      <div id="mobile-container" v-if="showMobileSidebar" class="">
        <nav
          id="mobile-sidebar"
          class="mobile sidebar-left-nav bg-white w-80 h-screen top-0 left-0 z-50 fixed shadow-2xl overflow"
        >
          <div class="flex flex-col h-full p-4">
            <div class="mt-7">
              <h3 class="text-xl font-semibold text-gray-900">
                <img
                  style="width: 50px"
                  src="./../../../assets/golfigo/logo.png"
                  alt=""
                />
              </h3>
            </div>
            <ul class="flex flex-col text-gray-600 mt-2">
              <li>
                <router-link
                  :to="{ name: 'landing-page' }"
                  class="flex py-1 font-semibold"
                  >{{ $t("NavbarFirst.home") }}
                </router-link>
              </li>
              <li>
                <a
                  href="#"
                  v-scroll-to="'#function-section'"
                  class="flex py-1 font-semibold"
                  v-if="['landing-page'].includes($route.name)"
                  >{{ $t("NavbarFirst.function") }}</a
                >
                <a
                  href="javascript:void(0)"
                  v-on:click="onToggle('function-section')"
                  v-else
                  class="flex py-1 font-semibold"
                  >{{ $t("NavbarFirst.function") }}</a
                >
              </li>
              <li>
                <a
                  href="#"
                  v-scroll-to="'#price-section'"
                  class="flex py-1 font-semibold"
                  v-if="['landing-page'].includes($route.name)"
                  >{{ $t("NavbarFirst.price") }}</a
                >
                <a
                  href="#"
                  v-on:click="onToggle('price-section')"
                  v-else
                  class="flex py-1 font-semibold"
                >
                  {{ $t("NavbarFirst.price") }}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  v-scroll-to="'#faq-section'"
                  class="flex py-1 font-semibold"
                  v-if="['landing-page'].includes($route.name)"
                  >FAQ</a
                >
                <a
                  href="#"
                  v-on:click="onToggle('faq-section')"
                  v-else
                  class="flex py-1 font-semibold"
                  >FAQ</a
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'contact-page' }"
                  class="flex py-1 font-semibold"
                  >{{ $t("NavbarFirst.contact") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'blog-page' }"
                  class="flex py-1 font-semibold"
                  >{{ $t("NavbarFirst.blog") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'login' }"
                  class="flex py-1 font-semibold"
                >
                  {{ $t("NavbarFirst.login") }}
                </router-link>
              </li>
              <li class="mt-2">
                <router-link
                  :to="{ name: 'price' }"
                  class="px-8 py-1 text-white bg-theme-sidebar rounded-md"
                >
                  {{ $t("NavbarFirst.register") }}
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import Locale from "@core/layouts/components/app-navbar/components/Locale";

export default {
  name: "NavbarComponent",
  components: {
    Locale,
  },
  data() {
    return {
      showMobileSidebar: false,
    };
  },
  mounted() {},
  methods: {
    toggleMobileSidebar() {},
    onToggle(target) {
      this.$router.push({ name: "landing-page", query: { target: target } });
    },
  },
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
</style>
