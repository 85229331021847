<template>
  <div class="flex flex-col md:flex-row bg-theme-deep-teal p-5 mt-4">
    <div class="flex md:w-1/2 md:mt-10 md:mb-10">
      <h1 class="md:ml-20 text-5xl text-white mb-10 md:mb-10">
        {{ $t('AppFooter.title')}}<br />
        {{ $t('AppFooter.subTitle')}}
        <br><br><br><h5>Connectivo AB</h5>
        <h5>{{ $t('AppFooter.copyright') }} © {{ new Date().getFullYear() }} Golfigo</h5>
      </h1>
    </div>
    <div class="md:w-1/2">
      <div class="grid grid-cols-1 md:grid-cols-3 md:mt-10 md:mb-10">
        <div></div>
        <div class="flex flex-col">
          <h2 class="text-2xl text-theme-sidebar mb-2">Golfigo</h2>
          <ul>
            <li class="text-white mb-2">
              <a class="text-white" href="#">{{ $t('NavbarFirst.aboutGolfigo')}}</a>
            </li>
            <li class="text-white mb-2">
              <!-- <a class="text-white" href="#function-section">Funktioner</a> -->
              <router-link :to="{ name: 'functions' }" class="text-white"
                >{{ $t('NavbarFirst.function') }}</router-link
              >
            </li>
            <li class="text-white mb-2">
              <router-link class="text-white" :to="{ name: 'price' }"
                >{{ $t('NavbarFirst.price') }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="flex flex-col">
          <h2 class="text-2xl text-theme-sidebar mb-2">{{ $t('NavbarFirst.others') }}</h2>
          <ul>
            <li class="text-white mb-2">
              <router-link class="text-white" :to="{ name: 'contact-page' }"
                >{{ $t('NavbarFirst.customerSupport') }}</router-link
              >
            </li>
            <li class="text-white mb-2">
              <a
                @click="downloadTermsOfPurchase"
                class="text-white"
                href="javascript:void(0);"
                >{{ $t('NavbarFirst.termsAndCondition') }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import download from "downloadjs";
export default {
  name: "FooterComponent",
  data() {
    return {};
  },
  methods: {
    downloadTermsOfPurchase() {
      this.$useJwt
        .customLanding(
          { URL: "/terms_of_purchase", method: "get" },
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/pdf",
            },
          }
        )
        .then((res) => {
          const content = res.headers["content-type"];
          download(res.data, "termsOfPurchase", content);
        })
        .catch((err) => {
          this.popup(
            err.response.data.message,
            "danger",
            this.$t("Message.Failed"),
            "AlertTriangleIcon"
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
